a {
    font-weight: 500;
    color: #646cff;
    text-decoration: inherit;
}

a:hover {
    color: #535bf2;
}

.bg-custom-purple {
    background-color: #6A0DAD;
}

.border-custom-purple {
    border-color: #6A0DAD;
}

.border-custom-white {
    border-color: white;
}

.bg-custom-blue {
    background-color: #00E5FF;
}

.bg-light-purple {
    background-color: #E0DDF5;
}

.text-purple {
    color: #6A0DAD;
}

.bg-highlight-blue {
    background-color: #B2D9FF;
}

.bg-highlight-purple {
    background-color: #D9B2FF;
}

.bg-header-purple {
    background-color: #a64dff;
}

.page-border-purple {
    border: 2px solid #6A0DAD;
}

.table-border-purple {
    border: 1px solid #000;
}

.bg-header-gray {
    background-color: rgb(165, 164, 164);
}

.bg-header-white {
    background-color: white;
}

.bg-header-yellow {
    background-color: yellow;
}

.bg-header-black {
    background-color: #000;
}

.text-header-purple {
    color: white;
}

.text-sm {
    font-size: 12px !important;
    font-weight: 600 !important;
}

h1 {
    font-size: 3.2em;
    line-height: 1.1;
}

button {
    border-radius: 8px;
    border: 1px solid transparent;
    padding: 0.6em 1.2em;
    font-size: 1em;
    font-weight: 500;
    font-family: inherit;
    background-color: #1a1a1a;
    cursor: pointer;
    transition: border-color 0.25s;
}

button:hover {
    border-color: #646cff;
}

button:focus,
button:focus-visible {
    outline: 4px auto -webkit-focus-ring-color;
}

@media print {
    body {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        background-color: white !important;
    }

    * {
        box-sizing: border-box;
    }

    html,
    body {
        width: 100%;
        height: 100%;
    }

    .page-border-purple,
    .bg-custom-purple,
    .table-border-purple {
        border-color: #6a0dad;
    }

    .page-break {
        page-break-inside: avoid;
    }

    body, h1, h2, h3, h4, h5, p, th, td {
        font-size: 10px;
        line-height: 1.2;
    }

    @page {
        size: A4;
        margin: 10mm;
    }

    table {
        table-layout: fixed;
        font-size: 8px;
    }

    img {
        max-height: 50px;
        width: auto;
    }
}
